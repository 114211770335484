import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Modal } from "react-bootstrap"
// import delaware from "../../data/economic-impact/delaware"

const DelawareEconomicImpactPage = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover2018Img: file(relativePath: { eq: "economic-impact/D2018.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover2006Img: file(relativePath: { eq: "economic-impact/D2006.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover2001Img: file(relativePath: { eq: "economic-impact/D2001.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover1991Img: file(relativePath: { eq: "economic-impact/D1991.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover2013Img: file(relativePath: { eq: "economic-impact/D2013.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      delawareImg: file(relativePath: { eq: "economic-impact/delaware.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      delawareVideoImg: file(
        relativePath: { eq: "economic-impact/delaware-video.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Delware Economic Impact" />
      <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <BackgroundImage
              Tag="section"
              className="swiper-slide slide-center post-hero"
              fluid={data.topImage.childImageSharp.fluid}
            >
              <div className="slide-content row text-center">
                <div className="col-12 mx-auto inner">
                  <nav
                    data-aos="zoom-out-up"
                    data-aos-delay="800"
                    aria-label="breadcrumb"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/economic-impact">Economic Impact</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        State of Delaware
                      </li>
                    </ol>
                  </nav>
                  <h1 className="mb-0 text-white effect-static-text">
                    State of Delaware
                  </h1>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
      </section>
      <section className="section-1 process offers">
        <div className="container pb-5">
          <div className="row intro">
            <div className="col-lg-8">
              <span className="pre-title m-0">Statewide Economic Impact</span>
              <h2>
                Delaware{" "}
                <span className="featured">
                  <span>Airports</span>
                </span>
              </h2>

              <p className="text-max-800">
                With proposed changes to the Aviation Laws before the State
                Legislature in 1990, the Delaware Department of Transportation
                contracted with R.A. Wiedemann &amp; Associates to produce a
                statewide study of the economic impacts of aviation in Delaware.
              </p>
              <p>
                With numerous updates tracking the growth of aviation economic
                activity, information from these studies have been used to
                support efforts to gain airline service within the State,
                protect general aviation airports, and publicize the very large
                contribution of jobs to the overall economy.
              </p>
              <p>
                The studies have been received favorably by both the government
                officials and the general public.
              </p>
            </div>
            <div className="col-lg-4 mt-5">
              <Img
                className="d-none d-lg-block report-shaddow"
                fluid={data.delawareImg.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
        <div className="container full">
          <div className="row justify-content-center text-center items">
            <div className="col-6 col-md-4 col-lg-2 item">
              <div className="step">
                <span>1991</span>
              </div>
              <div className="px-lg-4  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover1991Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$750 million</h5>
            </div>
            <div className="col-6 col-md-4 col-lg-2 item">
              <div className="step">
                <span>2001</span>
              </div>
              <div className="px-lg-4  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover2001Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$909.6 million</h5>
            </div>
            <div className="col-6 col-md-4 col-lg-2 item">
              <div className="step">
                <span>2006</span>
              </div>
              <div className="px-lg-4  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover2006Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$989.2 million</h5>
            </div>
            <div className="col-6 col-md-4 col-lg-2 item">
              <div className="step">
                <span>2013</span>
              </div>
              <div className="px-lg-4  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover2013Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$1.07 billion</h5>
            </div>
            <div className="col-6 col-md-4 col-lg-2 item">
              <div className="step">
                <span>2018</span>
              </div>
              <div className="px-lg-4  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover2018Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$1.12 billion</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section-2 odd">
        <div className="container ">
          <div className="row  intro">
            <div className="col-lg-5 ">
              <h2>
                Delaware{" "}
                <span className="featured">
                  <span>Video</span>
                </span>
              </h2>
              <p className="text-max-800 pb-5">
                For the 2018 Economic Impact Analysis, the following video was
                developed to better promote and explain the significant value of
                airports in the state of Delaware.
              </p>
            </div>
            <div className="col-lg-7">
              <a
                onClick={handleShow}
                href="#!"
                data-toggle="modal"
                data-target="#videoModal"
                className="square-image d-flex justify-content-center align-items-center"
              >
                <i className="icon bigger fas fa-play clone"></i>
                <i className="icon bigger fas fa-play"></i>
                <Img
                  className="fit-image"
                  fluid={data.delawareVideoImg.childImageSharp.fluid}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-80w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="videoModal"
            class="embed-responsive-item"
            src={`https://www.youtube.com/embed/_reW9zZub-U?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </Layout>
  )
}

export default DelawareEconomicImpactPage
